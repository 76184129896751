.mainContainer {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #eaeaea;

  h2 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 40px;
    color: #00162f;
  }
}

/* .sliderArrowRight,
.sliderArrowLeft {
  background: #ffffff;
  padding: 5px;
  bottom: 25px;
  border-radius: 5px;
  z-index: 0;

  &::before {
    color: #000;
  }

  &:hover {
    background-color: #fff !important;
  }
} */
/*
.sliderArrowRight {
  right: 43%;

  @media screen and (max-width: 768px) {
    right: calc(35% + 1px);
    bottom: 12px;
  }
}

.sliderArrowLeft {
  left: 43%;

  @media screen and (max-width: 768px) {
    left: calc(30% + 1px);
    bottom: 12px;
  }
} */

.imageWrapper {
  border-radius: 15px;
  img {
    width: 100%;
    object-fit: contain;
  }
}

.card {
  width: 800px;
  height: auto;
  padding: 20px 20px 40px 20px;
  background-color: #000;
  border-radius: 25px;
  color: white;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 300px;
  }

  .textWrapper {
    justify-content: space-between;
    display: flex;
    text-align: start;
  }
  .cardUserName {
    font-size: 18px;
    font-weight: 600;
    overflow-wrap: anywhere;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .cardUserSub {
    font-size: 14px;
  }

  .flagWrapper {
    display: flex;
    align-items: center;
    font-size: 12px;

    .countryName {
      margin-right: 5px;
    }
  }

  .ratingTitle {
    font-size: 14px;
    font-weight: 600;
  }

  .testimonialText {
    margin-top: 10px;
    font-size: 25px;
    font-weight: 700;
    text-align: start;
  }
}

.carousel_container {
  @media only screen and (min-width: 767px) {
    justify-content: center !important;
  }
}
