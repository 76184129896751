@import 'src/styles/import';

.container {
  display: flex;
  background-image: url('../../../../assets/images/SubscriptionBg.png');
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 45px;
  background-color: #f5f5f5;

  h2 {
    font-weight: 700;
  }

  .grid {
    margin-top: 20px;
    max-width: 1393px;
  }

  @media only screen and (max-width: 767px) {
    padding: 4rem 0;

    .grid {
      transform: scaleX(0.9);
      padding-top: 20px;
    }
  }

  p {
    margin-bottom: 3rem;
  }

  .subscriptionFree {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-right: 10px;
    margin-left: 10px;
    align-items: center;
    padding: 8px 3rem;
    gap: 8px;
    background: #3f50ea;
    border: 1px solid #077dfd;
    border-radius: 45px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;

    @include breakpoint(tablet-desktop) {
      padding: 12px 10rem;
    }
  }

  .trialSubscription {
    background-color: #e7f0e1;
    border-radius: 12px;
    padding: 16px 40px;
    margin: 18px 28px;
    text-align: center;
    border: 1px solid #62af70;
  }

  .cardContainerWrapper {
    padding: 8px;
  }

  .cardContainer1 {
    height: auto;
    min-height: 360px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    padding-bottom: 20px;

    .cardHeader1 {
      width: 97%;
      background-color: rgb(233, 234, 243);
      margin: 5px;
      margin-top: 5px;
      height: 140px;
      border-radius: 30px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .badge_icon_android {
        width: 20px;
        margin-right: 5px;
      }

      .badge_icon_ios {
        width: 20px;
        margin-right: 10px;
      }

      h5 {
        font-weight: 700;
      }

      .icon {
        width: 70px;
        height: 80px;
        margin-left: 20px;
      }

      img {
        width: 90px;
        height: auto;
        margin-right: 20px;
      }

      .sub_title {
        font-weight: 500;
        font-size: 16px;

        @media only screen and (min-width: 767px) {
          font-size: 14px;
        }

        @media only screen and (min-width: 767px) and (max-width: 1368px) {
          font-size: 11px;
        }

        .deviceName {
          color: gray;
        }
      }
    }

    .contents {
      display: inline-flex;
      width: 90%;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;

      p {
        margin-bottom: 10px;
      }
    }
  }
}

.to_be_announced {
  padding: 2px 5px;
  background-color: rgb(209, 211, 235);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
}

.description_text {
  font-size: 16px;
}

.bannerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 767px) {
    padding: 4rem 0;
    height: 500px;

    .grid {
      transform: scaleX(0.9);
    }
  }

  p {
    margin-bottom: 3rem;
  }

  .webSubContainer {
    position: relative;
    width: 100%;

    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      color: white;
      right: 0;
      text-align: center;

      .contentInner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }

      h2 {
        font-weight: 800;
        font-size: 40px;
        margin-bottom: 30px;
      }

      h6 {
        font-weight: 300;
        font-size: 25px;
        margin-bottom: 40px;
      }

      .bold {
        font-weight: 500;
      }

      p {
        color: white;
        font-size: 1.8em;
        margin-bottom: 25px !important;
        font-weight: 300 !important;
      }

      button {
        width: 250px;
        height: 55px;
        border-radius: 50px;
        color: white;
        font-weight: 500;
        font-size: 1.3em;
        color: #077dfd;
        background-color: white;
        border: none;
      }
    }

    img {
      width: 100%;
      height: auto;
      min-height: 400px;
    }
  }

  .mSubContainer {
    position: relative;
    height: 100%;

    .content {
      position: absolute;
      bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;

      p {
        color: white;
        font-size: 22px;
        margin-bottom: 15px;
        width: 80%;
        text-align: center;
      }

      button {
        width: 250px;
        height: 60px;
        border-radius: 50px;
        color: white;
        font-size: 26px;
        font-weight: 500;

        border: none;
      }
    }
  }

  .trialSubscription {
    background-color: #e7f0e1;
    border-radius: 12px;
    padding: 16px 40px;
    margin: 18px 28px;
    text-align: center;
    border: 1px solid #62af70;
  }
}