.container {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 999;
  background: white;

  .header {
    padding: 0.5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;

    @media only screen and (max-width: 1279px) {
      padding: 0 0 0 8px;
      display: flex;
      padding-right: 1rem;
      justify-content: space-between;
    }

    .iconContainer {
      color: #050505;
      margin-right: 0.5rem;
      transform: rotate(180deg);
      margin-top: 10px;
    }

    .brandTM {
      margin: 8px 0;
      cursor: pointer;

      img {
        height: 42px;
        width: 230px;
      }
    }

    .mbrandTM {
      margin: 8px 0;
      cursor: pointer;

      img {
        height: 32px;
      }
    }

    .navigation {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      @media only screen and (max-width: 1279px) {
        display: none;
      }

      .actionButton {
        margin-left: 16px;
        color: #050505;
        font-size: 15px;
        font-weight: normal;
      }
    }

    .actionButtonAuth {
      width: 150px;
      margin: 0 8px;

      @media only screen and (max-width: 1279px) {
        display: none;
      }
    }
  }

  .centerLogo {
    @media only screen and (max-width: 1279px) {
      justify-content: flex-start;

      .brandTM {
        width: 100%;
        text-align: center;
        margin-left: -30px;
      }
    }
  }

  .fixed {
    position: fixed;
    background-color: white;
  }

  .desktop {
    display: initial;

    @media only screen and (max-width: 1279px) {
      display: none !important;
    }
  }

  .mobile {
    display: none !important;

    @media only screen and (max-width: 1279px) {
      display: initial !important;
    }
  }

  .userProfile {
    display: flex;
    margin-left: 12px;
    @media only screen and (min-width: 1025px) {
      margin-right: 20px;
    }

    .notificationsIcon {
      display: flex;
      margin-right: 20px;

      span.material-icons {
        color: red;
      }
    }
  }
}

.menuDialog {
  background-color: rgba(255, 255, 255, 0.8);
}

.toolbar {
  justify-content: flex-end;

  .closeIcon {
    font-size: 40px;
  }
}

.actionMenu {
  justify-content: center;
  flex-direction: column;

  h5 {
    font-size: 16px;
    font-weight: normal;
  }

  img {
    margin-right: 8px;
  }
}

.mobileMenuWrapper {
  text-align: center;
  height: 100%;

  h2 {
    margin: 35px 0;
    font-size: 32px;
    font-weight: bold;
  }

  ul {
    height: 75%;
  }

  ul li {
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    margin: 18px 0;
  }
}

.menuPopper {
  position: relative;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: auto;

  .paper {
    height: 100%;
    background: transparent;

    .backgroundBlur {
      width: 100%;
      height: 100%;
      filter: blur(8px);
      position: fixed;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(8px);
    }

    .iconClose {
      font-size: 2rem;
      color: black;
      position: absolute;
      right: 10px;
      z-index: 1;
    }

    .fixedHeader {
      position: fixed;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      background: white;
      z-index: 1;
    }

    .menuHeader {
      font-size: 38px;
      font-weight: 600;
      margin: 0px 15px;
    }

    .menuItem {
      font-weight: 600;
    }

    .menu {
      padding-top: 3.8rem;
    }

    .platforms {
      position: absolute;
      margin: 20px 0px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .divider {
        height: 1px;
        width: 90%;
        background-color: gray;
        opacity: 0.5;
      }

      .apps {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-top: 30px;

        .googleBadges {
          color: black;
          display: flex;
          flex-direction: column;

          img {
            height: 45px;
            margin-bottom: 15px;
          }
        }
        .appleBadges {
          color: black;
          display: flex;
          flex-direction: column;

          img {
            height: 50px;
            margin-bottom: 10px;
          }
        }
      }

      p {
        text-align: center;
        color: #000;
        font-style: italic;
        font-weight: bold;
      }
    }
  }
}

.qsStickyBanner {
  width: 100%;
  padding: 7px 15px 8px;
  gap: 5px;
  z-index: 1;
  position: fixed;
  top: 75px;
  background: linear-gradient(
    180deg,
    #077dfd -255.45%,
    #ffffff -175.19%,
    #f5faff -43.58%,
    #b6daff 155.45%
  );
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.15);

  .mQS_banner {
    height: 50px;
  }
}

.scrollQsStickyBanner {
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scrollQsStickyBannerDown {
  animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.referralSection {
  display: flex;
  flex-direction: row;
  text-align: right;

  span {
    font-size: 12px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }

  .profilePic {
    width: 50px;
    height: 50px;
    margin-left: 5px;
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-75px);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-75px);
  }
  100% {
    transform: translateY(0);
  }
}

.referralModal {
  border-radius: 20px;
  width: 350px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}
.modalContainer {
  width: 100%;
  height: auto;

  .modalHeader {
    height: 50px;
    background-color: #b0d7fe;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    p {
      margin-bottom: 0px;
      font-weight: 600;
    }
  }
}

.showMoreBtn {
  margin-left: 16px;
  color: #050505;
  font-size: 15px;
  font-weight: normal;
  margin: 0px 10px;

  @media only screen and (max-width: 1279px) {
    display: none;
  }
}

.badgeMainWrapper {
  padding: 15px;
  flex-wrap: wrap;
}